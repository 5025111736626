<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">

                    <div><router-link to="/Admin/store/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>
                    <div><el-input v-model="where.phone" placeholder="请输入用户手机号"></el-input></div>
                    <div><el-date-picker format="yyyy-MM-dd HH:mm" v-model="where.times" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker></div>
                    <div><el-button icon="el-icon-search" @click="get_store_list()">条件筛选</el-button></div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
            </div>

            <div class="admin_table_main">
                <el-table :data="list" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column>
                    <el-table-column prop="F_VR_NIC" label="用户昵称">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt>
                                    <el-popover placement="left" trigger="click" width="600">
                                        <img :src="scope.row.F_VR_TOUX||require('@/assets/default_pic.png')" width="100%" />
                                        <img
                                                slot="reference"
                                                :src="scope.row.F_VR_TOUX||require('@/assets/default_pic.png')"
                                                :alt="scope.row.F_VR_TOUX"
                                                style="max-height: 50px;max-width: 50px; padding: 5px"
                                        />
                                    </el-popover>
                                    </dt>
                                <dd class="table_dl_dd_all_30">昵称： {{ scope.row.F_VR_NIC }}</dd>
                                <dd class="table_dl_dd_all_16_gray">注册时间： {{ scope.row.login_time|formatDate}}</dd>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="手机号码"></el-table-column>
                    <el-table-column label="禁用状态">
                        <template slot-scope="scope">
                            <div :class="scope.row.F_TI_STATE==1?'green_round':'gray_round'" @click="state_status(scope.row.id)"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="VIP会员">
                        <template slot-scope="scope">
                            <div :class="scope.row.F_TI_HUIY==1?'green_round':'gray_round'" @click="huiy_status(scope.row.id)"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="SVIP会员">
                        <template slot-scope="scope">
                            <div :class="scope.row.F_TI_SVIPHY==1?'green_round':'gray_round'" @click="sviphy_status(scope.row.id)"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="220px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'store_form',params:{id:scope.row.id}})">编辑</el-button>
                            <!--<el-button icon="el-icon-tickets" @click="read_store_info(scope.row)">查看</el-button>-->
                            <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>

        <el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
            <ul class="store_list_ul">
                <li>
                    <h4>昵称:</h4>{{info.nickname|'-'}}
                </li>
                <li>
                    <h4>头像:</h4><el-image style="width: 60px; height: 60px" :src="info.store_logo"></el-image>
                </li>
                <!--<li>
                    <h4>入驻公司:</h4>{{info.store_company_name|'-'}}-{{info.area_info}}-{{info.store_address}}
                </li>
                <li>
                    <h4>营业执照号码:</h4>{{info.legal_person|'-'}}-{{info.business_license_no|'-'}}
                </li>-->
                <li>
                    <h4>身份证:</h4><el-image :preview-src-list="read_pic(info.business_license)" style="width: 60px; height: 60px" :src="info.business_license"></el-image>-<el-image :preview-src-list="read_pic(info.id_card)" style="width: 60px; height: 60px" :src="info.id_card"></el-image>
                </li>

                <li>
                    <h4>身份证号码:</h4>{{info.id_card_no|'-'}}
                </li>
                <li>
                    <h4>手机号:</h4><!--{{info.emergency_contact}}- -->{{info.emergency_contact_phone}}
                </li>
                <li>
                    <h4>禁用状态:</h4><el-radio v-model="info.store_status" :label="0">关闭</el-radio>
                    <el-radio v-model="info.store_status" :label="1">开启</el-radio>
                </li>
                <li v-if="info.store_status==0">
                    <h4>禁用原因:</h4><el-input type="textarea" placeholder="关闭原因！" v-model="info.store_close_info"></el-input>
                </li>
                <!--<li v-if="info.store_verify==0">
                    <h4>店铺审核:</h4>
                    <el-radio v-model="store_verify" :label="0">等待审核(忽略)</el-radio>
                    <el-radio v-model="store_verify" :label="1">同意</el-radio>
                    <el-radio v-model="store_verify" :label="2">拒绝</el-radio>
                </li>-->

            </ul>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">关 闭</el-button>
                <el-button type="primary" @click="update_store()">确 定</el-button>
            </span>
            </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          list:[],
          total_data:0, // 总条数
          page_size:20,
          current_page:1,
          select_id:'',
          dialogVisible:false,
          info:{},
          store_verify:0,
          where:{
              times:[],
              phone:''
          },
      };
    },
    watch: {},
    computed: {},
    methods: {
        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.id);
            });
            this.select_id = ids.join(',');
        },
        get_store_list:function(){
            let where = {};
            where.page = this.current_page;
            where.params = this.where;
            this.$get(this.$api.getStoreList,where).then(res=>{
                this.page_size = res.data.per_page;
                this.total_data = res.data.total;
                this.list = res.data.data;
                this.current_page = res.data.current_page;
            })
        },
        read_store_info(e){
            this.info = e;
            this.store_verify = this.info.store_verify;
            this.dialogVisible=true;
        },
        read_pic(e){
            return [e];
        },
        update_store:function(){
            if(this.info.store_status==0 && this.info.store_close_info == ''){
                return this.$message.error('请填写关闭店铺的原因！');
            }
            this.info.store_verify = this.store_verify;
            this.$post(this.$api.StorePass,this.info).then(res=>{
                this.$message.success('修改成功');
                this.dialogVisible = false;
                this.get_store_list();
            });
        },
        // 分页改变
        current_change:function(e){
            this.current_page = e;
            this.get_store_list();
        },
        // 删除处理
        del:function(id){
            let _this = this;
            if(this.$isEmpty(id)){
                return this.$message.error('请先选择删除的对象');
            }
            this.$post(this.$api.delStore,{id:id}).then(function(res){
                if(res.code == 200){
                    _this.get_store_list();
                    return _this.$message.success("删除成功");
                }else{
                    return _this.$message.error("删除失败！"+res.msg);
                }
            });
        },
        // 指定ID修改状态
        state_status:function(id){
            this.$post(this.$api.stateStoretStatus,{id:id}).then(res=>{
                if(res.code==200){
                    this.$message.success('操作成功');
                }else{
                    this.$message.success('操作失败');
                }
                this.get_store_list();
            });
        },
        // 指定ID修改状态
        huiy_status:function(id){
            this.$post(this.$api.huiyStoretStatus,{id:id}).then(res=>{
                if(res.code==200){
                    this.$message.success('操作成功');
                }else{
                    this.$message.success('操作失败');
                }
                this.get_store_list();
            });
        },
        // 指定ID修改状态
        sviphy_status:function(id){
            this.$post(this.$api.sviphyStoretStatus,{id:id}).then(res=>{
                if(res.code==200){
                    this.$message.success('操作成功');
                }else{
                    this.$message.success('操作失败');
                }
                this.get_store_list();
            });
        }
    },
    created() {
        this.get_store_list();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
.store_list_ul li{
    border-bottom: 1px solid #efefef;
    margin:0 0 30px 0;
    padding-bottom: 15px;
    color:#666;
    h4{
        margin-bottom: 15px;
        color:#333;
    }
}
</style>